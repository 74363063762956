<template>
  <div>
    <div class="main-img-wrapper">
      <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/fe019c3b-10bf-4be8-ae00-06926a4da0ca.jpg" alt="主图" class="img-fluid">
      <div class="main-img-caption flex flex-column flex-center">
        <h1 class="main-img-title animated slideInDown">禾苗</h1>
        <p class="animated slideInDown">专注于母婴行业<strong>智慧零售</strong>的会员精准营销系统</p>
      </div>
    </div>
    <section class="feature">
      <b-container>
        <b-row class="flex-center">
          <b-col cols="12">
            <div class="feature-img-wrapper">
              <img src="../assets/hemiao.gif" alt="功能" class="img-fluid">
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="feature-list">
        <div class="feature-item stripe">
          <b-container>
            <b-row class="flex-around">
              <b-col class="article flex flex-column flex-center animated" ref="article1" :class="article1" md="4">
                <b-row class="flex-center">
                  <b-col cols="12" md="12">
                    <article class="content">
                      <h2 class="caption">智能零售</h2>
                      <p>Plus会员体系</p>
                      <p>线上开卡与绑定</p>
                      <p>实时销售智能化</p>
                      <p>获客成本、转化率、销售数据在线监控</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/615e43b7-c0c5-4155-af76-29fb021c157d.png" alt="智能零售" class="img-fluid">
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="feature-item exchange">
          <b-container>
            <b-row class="flex-around">
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/28c38c06-a10e-450e-81be-24eb1bde0290.png" alt="数字化会员" class="img-fluid">
              </b-col>
              <b-col class="article flex flex-column flex-center animated" ref="article2" :class="article2" md="4">
                <b-row class="flex-center">
                  <b-col cols="12" md="12">
                    <article class="content">
                      <h2 class="caption">数字化会员</h2>
                      <p>会员生理轴分布</p>
                      <p>信息消费数字化</p>
                      <p>可视化数据分析</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="feature-item stripe">
          <b-container>
            <b-row class="flex-around">
              <b-col class="article flex flex-column flex-center animated" md="4" ref="article3" :class="article3">
                <b-row class="flex-center">
                  <b-col cols="12" md="12">
                    <article class="content">
                      <h2 class="caption">线上商城+线下门店</h2>
                      <p>趣味游戏互动</p>
                      <p>线上预约服务、到店体验</p>
                      <p>线上秒杀产品、线下配送或到店自提</p>
                      <p>线上获取优惠券、到店享受优惠活动</p>
                    </article>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="img" cols="6" md="3">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/639ba2c1-de09-4069-a3dd-10e1e91fec7a.png" alt="线上商城+线下门店" class="img-fluid">
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      article1: '',
      article2: '',
      article3: ''
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      const scrollDistance = document.documentElement.scrollTop || document.body.scrollTop
      this.addAnimate({ ele: 'article1', model: 'article1', animate: 'fadeInLeft', scrollDistance })
      this.addAnimate({ ele: 'article2', model: 'article2', animate: 'fadeInRight', scrollDistance })
      this.addAnimate({ ele: 'article3', model: 'article3', animate: 'fadeInLeft', scrollDistance })
    })
  },
  destoryed () {
    window.removeEventListener('scroll')
  },
  methods: {
    addAnimate ({ ele, model, animate, space = 200, scrollDistance }) {
      if (this.$refs[ele]) {
        this[model] = scrollDistance + space >= this.$refs[ele].offsetTop ? animate : ''
      }
    }
  }
}
</script>

<style scoped lang="less">
@distance: 40px;
.feature-img-wrapper {
  padding: 120px 0;
}

.feature-item {
  padding: @distance * 2 0;
}

.content:after {
  position: absolute;
  bottom: -30px;
  left: 0;
  content: '';
  height: 2px;
  width: 30px;
  background: #3167ff;
  border-radius: 30px;
}

.content {
  position: relative;
  // width: max-content;
  // margin: 0 auto;
  // h1, p {
  //   word-break: keep-all;
  // }
  .caption {
    margin-bottom: 20px;
    color: #333;
  }
  p {
    color: #666;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .exchange {
    .article {
      order: -1;
    }
  }
  .caption {
    font-size: 20px;
    text-align: center
  }
  .content {
    margin-bottom: 50px;
    text-align: center
  }
  .content::after {
    display: none;
  }
  .feature-img-wrapper {
    padding: @distance 0;
  }
  .feature-item {
    padding: @distance 0;
  }
}
</style>
